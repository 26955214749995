export function makeShortId(longId) {
    const notDigitOrChar = /[^\w |\d]|\s/g
    const initialUnderscore = /^_+/
    const twoOrMoreUnderscores = /__+/g
    const finalUnderscore = /_+$/
    const accents = /[\u0300-\u036f]/g
    const shortId = longId
        .toLowerCase()
        .normalize('NFD')
        .replace(accents, '')
        .replace(notDigitOrChar, '_')
        .replace(initialUnderscore, '')
        .replace(twoOrMoreUnderscores, '_')
        .replace(finalUnderscore, '')
        .slice(0, 32)
    return shortId
}

export function splitEmails(emailString) {
    // eslint-disable-next-line no-useless-escape
    const nonEmailChars = /[^\w |\d |@ |. |_|-]|\s/g
    const allEmailsArray = emailString.replace(nonEmailChars, '\n').split('\n')
    const filteredEmailsArray = allEmailsArray.filter(email => email.length !== 0)
    var emailObjectsArray = []
    filteredEmailsArray.forEach(email => {
        emailObjectsArray.push({ email: email, status: null })
    })
    return emailObjectsArray
}

export function sortArray(array, sortParameter, how, toString) {
    const arrayCopy = Array.from(array)
    if (how === 'ascending') {
        var ascSortedArray
        if (toString) {
            ascSortedArray = arrayCopy.sort(function(a, b) {
                if (a[sortParameter].toString().toLowerCase() < b[sortParameter].toString().toLowerCase()) {
                    return -1
                }
                if (a[sortParameter].toString().toLowerCase() > b[sortParameter].toString().toLowerCase()) {
                    return 1
                }
                return 0
            })
        } else {
            ascSortedArray = arrayCopy.sort(function(a, b) {
                if (a[sortParameter] < b[sortParameter]) {
                    return -1
                }
                if (a[sortParameter] > b[sortParameter]) {
                    return 1
                }
                return 0
            })
        }

        return ascSortedArray
    } else if (how === 'descending') {
        var descSortedArray
        if (toString) {
            descSortedArray = arrayCopy.sort(function(a, b) {
                if (a[sortParameter].toString().toUpperCase() > b[sortParameter].toString().toUpperCase()) {
                    return -1
                }
                if (a[sortParameter].toString().toUpperCase() < b[sortParameter].toString().toUpperCase()) {
                    return 1
                }
                return 0
            })
        } else {
            descSortedArray = arrayCopy.sort(function(a, b) {
                if (a[sortParameter] > b[sortParameter]) {
                    return -1
                }
                if (a[sortParameter] < b[sortParameter]) {
                    return 1
                }
                return 0
            })
        }
        return descSortedArray
    }
}

export function sortDateArray(array, sortParameter, how) {
    const arrayCopy = Array.from(array)
    var ascSortedArray = arrayCopy.sort((a, b) => {
        if (how === 'ascending') {
            return new Date(a[sortParameter]) - new Date(b[sortParameter])
        } else if (how === 'descending') {
            return new Date(b[sortParameter]) - new Date(a[sortParameter])
        }
    })
    return ascSortedArray
}

// allow the following routes without Auth
export const publicNames = ['welcome', 'callback', 'features', 'pricing', 'invitation-review', 'invgen-single', 'login', 'trial-thank-you', 'privacy-policy']

export function parseURL(urlString) {
    var url = {}
    if (urlString.includes('/org/')) {
        var org = urlString.match(/\/org\/\d+/g)[0].match(/\d+/g)[0]
        url.oid = parseInt(org, 10)
    }
    if (urlString.includes('/space/')) {
        var space = urlString.match(/\/space\/\d+/g)[0].match(/\d+/g)[0]
        url.sid = parseInt(space, 10)
    }
    if (urlString.includes('/instance/')) {
        var iid = urlString.match(/\/instance\/\d+/g)[0].match(/\d+/g)[0]
        url.iid = parseInt(iid, 10)
    }
    if (urlString.includes('/snapshot/')) {
        var snid = urlString.match(/\/snapshot\/\d+/g)[0].match(/\d+/g)[0]
        url.snid = parseInt(snid, 10)
    }
    return url
}

export function defaultAccountType(email) {
    email = email || ''

    const switchSuffixes = [
        'usi.ch',
        'unige.ch',
        'uzh.ch',
        'epfl.ch',
        'unil.ch',
        'unilu.ch',
        'unibas.ch',
        'unibe.ch',
        'unine.ch',
        'unisg.ch',
        'ethz.zh',
        'unifr.ch'
    ]
    if (
        switchSuffixes.some(s => {
            return email.endsWith(s)
        })
    ) {
        return 'SWITCH'
    } else {
        return null
    }
}

export function humanFileSize(bytes, si) {
    var thresh = si ? 1000 : 1024
    if (Math.abs(bytes) < thresh) {
        return bytes + ' B'
    }
    var units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB']
    var u = -1
    do {
        bytes /= thresh
        ++u
    } while (Math.abs(bytes) >= thresh && u < units.length - 1)
    return bytes.toFixed(1) + ' ' + units[u]
}

export function truncateText(text, maxLength) {
    if (text) {
        if (text.length <= maxLength) {
            return text
        } else {
            const truncatedText = text.substring(0, maxLength)
            return truncatedText + '...'
        }
    }
}

export function isJson(str) {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}

export function getObjectValueByKey(objectName, objectValue) {
    return Object.keys(objectName).find(key => objectName[key] === objectValue)
}

export function isDateGreaterThanToday(date) {
    const todayStringUTC = new Date().toUTCString()
    const todayStringISO = new Date(todayStringUTC).toISOString().replace(/Z$/, '')
    const todayDateUTC = new Date(todayStringISO)
    const comparisonDate = new Date(date.replace(/\..*/, ''))
    if (comparisonDate > todayDateUTC) {
        return true
    } else {
        return false
    }
}

export function isEditorRole(role) {
    if (role && (role.toLowerCase() === 'editor' || role.toLowerCase().includes('editor'))) {
        return true
    } else {
        return false
    }
}

export function isViewerRole(role) {
    if (role && (role.toLowerCase() === 'viewer' || role.toLowerCase().includes('viewer'))) {
        return true
    } else {
        return false
    }
}

export function isManagerRole(role) {
    if (role && (role.toLowerCase() === 'manager' || role.toLowerCase().includes('manager'))) {
        return true
    } else {
        return false
    }
}

export function isAdminRole(role) {
    if (role && (role.toLowerCase() === 'space_admin' || role.toLowerCase().includes('space_admin'))) {
        return true
    } else {
        return false
    }
}

export function isDistributedInstance(instanceName) {
    if (instanceName && (instanceName.toLowerCase() === 'distributed' || instanceName.toLowerCase().includes('distributed'))) {
        return true
    } else {
        return false
    }
}

export function isMasterInstance(instanceName) {
    if (instanceName && (instanceName.toLowerCase() === 'master' || instanceName.toLowerCase().includes('master'))) {
        return true
    } else {
        return false
    }
}

export function isMasterOrDistributedInstance(instanceName) {
    if (
        instanceName &&
        (instanceName.toLowerCase() === 'master' ||
            instanceName.toLowerCase().includes('master') ||
            instanceName.toLowerCase() === 'distributed' ||
            instanceName.toLowerCase().includes('distributed'))
    ) {
        return true
    } else {
        return false
    }
}

export function isCurrentState(instanceName) {
    if (instanceName && instanceName.toLowerCase() === 'development') {
        return true
    } else {
        return false
    }
}

export function calculateDuration(date2, date1) {
    if (date2) {
        const dt1 = new Date(date1)
        const dt2 = new Date(date2)
        var diff = (dt2.getTime() - dt1.getTime()) / 1000
        if (diff < 60) {
            return Math.abs(Math.round(diff)) + ' sec'
        } else if (diff < 3599) {
            return Math.round((diff / 60) * 100) / 100 + ' min'
        } else {
            return Math.round((diff / 3600) * 100) / 100 + ' hr'
        }
    } else {
        return '-'
    }
}

export function calculateDateDifferenceInDays(firstDateString, secondDateString) {
    const date1 = new Date(firstDateString)
    const date2 = new Date(secondDateString)
    const diffferenceInTime = date2.getTime() - date1.getTime()
    var differenceInDays = diffferenceInTime / (1000 * 3600 * 24)
    return differenceInDays
}

export function roundNumber(number, decimalToRoundTo) {
    return parseInt(number, 20) === number ? number : parseFloat(number.toFixed(decimalToRoundTo))
}

export function getDateRangeArray(start, end) {
    var startDate = new Date(start)
    const datesArray = []
    // eslint-disable-next-line no-unmodified-loop-condition
    while (startDate <= end) {
        datesArray.push(new Date(startDate))
        startDate.setDate(startDate.getDate() + 1)
    }
    var formatedDates = datesArray.map(v => v.toISOString().slice(0, 10))
    return formatedDates
}
