import Vue from 'vue'
import Router from 'vue-router'
import auth from './auth/authService'
import store from 'store'
import vuexStore from './store'
import regionsMixin from './mixins/regions'

import homeRoutes from './modules/home/routes'
import snapshotRoutes from './modules/snapshot/routes'
import invitationRoutes from './modules/invitations/routes'
import userRoutes from './modules/user/routes'
import adminRoutes from './modules/admin/routes'
import { publicNames } from './utils'
const Callback = () => import('./views/Callback')
const TheLoginPage = () => import('./views/TheLoginPage')
const TrialThankYouPage = () => import('./views/TrialThankYouPage')
const PrivacyPolicy = () => import('./views/PrivacyPolicy')

const FourOFour = () => import('@/views/The404')

Vue.use(Router)

const baseRoutes = [
    {
        path: '/callback',
        name: 'callback',
        component: Callback
    },
    {
        path: '/',
        name: 'welcome'
    },
    {
        path: '/login',
        name: 'login',
        components: {
            default: TheLoginPage
        }
    },
    {
        path: '/trial-thank-you',
        name: 'trial-thank-you',
        components: {
            default: TrialThankYouPage
        }
    },
    {
        path: '/privacy-policy',
        name: 'privacy-policy',
        components: {
            default: PrivacyPolicy
        }
    }
]

const routes = baseRoutes
    .concat(homeRoutes)
    .concat(snapshotRoutes)
    .concat(invitationRoutes)
    .concat(userRoutes)
    .concat(adminRoutes)
    .concat([
        {
            path: '*',
            name: 'notfound',
            component: FourOFour
        }
    ])

const router = new Router({
    mode: 'history',
    routes
})

router.beforeEach((to, from, next) => {
    // allow access to root, callback and invitaitons, otherwise require user being authenticated
    if (publicNames.indexOf(to.name) > -1 || auth.isAuthenticated()) {
        if (to.params.snid && to.params.snid !== from.params.snid) {
            const targetSnapshot = vuexStore.state.orgStore.spacesWithPrimarySnapshots.filter(i => i.snid.toString() === to.params.snid.toString())
            if (targetSnapshot.length && targetSnapshot[0].region !== undefined && targetSnapshot[0].region < 2) {
                const currentRegionEnum = regionsMixin.methods.getCurrentRegionEnum().toString()
                const targetRegionEnum = targetSnapshot[0].region.toString()
                if (targetRegionEnum !== currentRegionEnum) {
                    location.href = regionsMixin.methods.changeRegionURL(
                        regionsMixin.methods.getRegionCodeFromEnum(targetRegionEnum),
                        location.origin + to.path
                    )
                    return null
                }
            }
        }
        return next()
    }
    store.set('nvTarget', to.path)
    router.push({ name: 'login', query: to.query })
})

export default router
