var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-app-bar",
    {
      directives: [
        {
          name: "scroll",
          rawName: "v-scroll",
          value: _vm.onScroll,
          expression: "onScroll"
        }
      ],
      attrs: {
        color:
          _vm.isScrolling || _vm.$route.name !== "privacy-policy"
            ? "transparent"
            : "rgb(23, 167, 193)",
        flat: !_vm.isScrolling,
        app: "",
        dark: "",
        "hide-on-scroll": ""
      }
    },
    [
      _c(
        "router-link",
        { attrs: { to: { name: "welcome" } } },
        [
          _c("v-img", {
            staticClass: "ml-3",
            attrs: {
              contain: "",
              "max-width": "110px",
              "max-height": "22px",
              src: require("@/assets/Nuvolos-transparent-white.svg")
            }
          })
        ],
        1
      ),
      _c("v-spacer"),
      _c(
        "v-toolbar-items",
        { staticClass: "pa-0" },
        [
          _c(
            "v-btn",
            {
              attrs: { text: "", href: "#requestTrial" },
              on: {
                click: function($event) {
                  $event.preventDefault()
                  return _vm.$vuetify.goTo("#requestTrial")
                }
              }
            },
            [_vm._v("request a trial")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }