import $axios from '@/backend'
import Vue from 'vue'
import { sortArray, isCurrentState } from '@/utils'
const getDefaultState = () => {
    return {
        currentFiles: [],
        currentTables: [],
        currentColumns: [],
        previewColumnHeaders: [],
        previewData: [],
        filesToDistribute: [],
        filesFetching: false,
        tablesFetching: false,
        columnsFetching: false,
        previewFetching: false,
        applicationsFetching: false,
        applicationImageFetching: false,
        restoringSnapshot: false,
        pathArray: [''],
        currentTreeLevel: null,
        applications: [],
        fileAreaType: 'files',
        fileOpened: {},
        imageList: [],
        stagingObjects: { files: [], tables: [], applications: [] },
        lastUploadedFiles: [],
        showUploadSnackbar: false,
        currentFileUnderRevisionData: { fileName: null, fid: null, previousVersions: [] },
        snapshotQueries: [],
        queryPreviewData: { headers: [], tableData: [], sql: '', qid: null },
        latestQueryBuilderQueryData: { table: null, tableId: null, columns: null, filters: null, rowLimit: null, query: null },
        latestQuery: null,
        fullQueryData: [],
        tablesPath: [],
        filePollingInterval: null,
        snapshotQuota: null
    }
}
const snapshotStore = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        resetSnapshotState(state) {
            if (state.filePollingInterval) {
                clearInterval(state.filePollingInterval)
            }
            Object.assign(state, getDefaultState())
        },
        setCurrentFiles: function(state, currentFiles) {
            state.currentFiles = currentFiles
        },
        setLatestQueryBuilderQueryData: function(state, { table, columns, tableId, filters, rowLimit, query }) {
            state.latestQueryBuilderQueryData.table = table
            state.latestQueryBuilderQueryData.columns = columns
            state.latestQueryBuilderQueryData.tableId = tableId
            state.latestQueryBuilderQueryData.filters = filters
            state.latestQueryBuilderQueryData.rowLimit = rowLimit
            state.latestQueryBuilderQueryData.query = query
        },
        clearCurrentFiles: function(state) {
            state.currentFiles = []
        },
        setFilesPollingInterval: function(state, interval) {
            state.filePollingInterval = interval
        },
        setSnapshotRestoreState(state, newVal) {
            state.restoringSnapshot = newVal
        },
        clearFilePollingInterval: function(state) {
            clearInterval(state.filePollingInterval)
        },
        emptyTableData: function(state, clearQueryData) {
            state.currentColumns = []
            state.previewColumnHeaders = []
            state.previewData = []
            state.queryPreviewData = { headers: [], tableData: [], sql: '', qid: null }
            if (clearQueryData === true) {
                state.fullQueryData = []
            }
        },
        setFullQueryData: function(state, queryFiles) {
            state.fullQueryData = queryFiles
        },
        setTablesPath: function(state, data) {
            state.tablesPath = data
        },
        setSnapshotQuotaData: function(state, newVal) {
            state.snapshotQuota = newVal
        },
        setCurrentTables: function(state, currentTables) {
            state.currentTables = sortArray(currentTables, 'short_id', 'ascending', true)
        },
        setQueryPreviewData: function(state, { data, sql, qid }) {
            if (data.length) {
                state.queryPreviewData = { headers: Object.keys(data[0]), tableData: data, sql: sql, qid: qid }
            } else {
                state.queryPreviewData = { headers: [], tableData: data, sql: sql, qid: qid }
            }
        },
        setSnapshotQueries: function(state, queryData) {
            const sortBtQid = sortArray(queryData, 'qid', 'descending', false)
            state.snapshotQueries = sortBtQid
            state.latestQuery = sortBtQid[0]
        },
        setCurrentColumns: function(state, currentColumns) {
            const sortedCols = sortArray(currentColumns, 'cid', 'ascending', false)
            state.currentColumns = sortedCols
        },
        setCurrentFileUnderRevisionData: function(state, { fileName, fid, prevData }) {
            state.currentFileUnderRevisionData.fileName = fileName
            state.currentFileUnderRevisionData.fid = fid
            state.currentFileUnderRevisionData.previousVersions = sortArray(prevData, 'snid', 'descending', false)
        },
        setPreviewData: function(state, { keys, data }) {
            state.previewColumnHeaders = keys
            state.previewData = data
        },
        setFilesToDistribute: function(state, newFiles) {
            state.filesToDistribute = newFiles
        },
        updateImageNamesList: function(state, imageList) {
            state.imageList = imageList
        },
        setPathArray: function(state, newVal) {
            state.pathArray = newVal
        },
        appendPathArray: function(state, dir) {
            state.pathArray.push(dir)
        },
        setTreeLevel: function(state, level) {
            state.pathArray.splice(level)
            if (state.pathArray.length === 0) {
                state.pathArray = ['']
            }
            state.currentTreeLevel = level
        },
        setApplications: function(state, apps) {
            state.applications = sortArray(apps, 'aid', 'descending', false)
        },
        setFetching: function(state, { fetchingType, val }) {
            if (fetchingType === 'files') {
                state.filesFetching = val
            } else if (fetchingType === 'tables') {
                state.tablesFetching = val
            } else if (fetchingType === 'columns') {
                state.columnsFetching = val
            } else if (fetchingType === 'preview') {
                state.previewFetching = val
            } else if (fetchingType === 'applicationImages') {
                state.applicationImageFetching = val
            } else if (fetchingType === 'applications') {
                state.applicationsFetching = val
            }
        },
        updateFileDescription: function(state, { newVal, fid }) {
            const files = state.currentFiles.filter(file => file.fid === fid)
            state.currentFiles[state.currentFiles.indexOf(files[0])].description = newVal
        },
        setFolderType: function(state, type) {
            state.fileAreaType = type
        },
        setOpenedFile: function(state, { aid, path }) {
            Vue.set(state.fileOpened, aid, path)
        },
        updateStagingObjects: function(state, { itemType, newItems, updateMode }) {
            if (itemType === 'file') {
                if (updateMode === 'add') {
                    const fids = state.stagingObjects.files.map(file => file.fid)
                    newItems.forEach(file => {
                        if (!fids.includes(file.fid)) {
                            state.stagingObjects.files.push(file)
                        }
                    })
                } else if (updateMode === 'remove') {
                    const fids = newItems.map(file => file.fid)
                    const filteredArray = state.stagingObjects.files.filter(function(file) {
                        return fids.indexOf(file.fid) < 0
                    })
                    state.stagingObjects.files = filteredArray
                }
            } else if (itemType === 'table') {
                if (updateMode === 'add') {
                    const tids = state.stagingObjects.tables.map(table => table.tid)
                    newItems.forEach(table => {
                        if (!tids.includes(table.tid)) {
                            state.stagingObjects.tables.push(table)
                        }
                    })
                } else if (updateMode === 'remove') {
                    const tids = newItems.map(table => table.tid)
                    const filteredArray = state.stagingObjects.tables.filter(function(table) {
                        return tids.indexOf(table.tid) < 0
                    })
                    state.stagingObjects.tables = filteredArray
                }
            } else if (itemType === 'application') {
                if (updateMode === 'add') {
                    const aids = state.stagingObjects.applications.map(application => application.aid)
                    newItems.forEach(application => {
                        if (!aids.includes(application.aid)) {
                            state.stagingObjects.applications.push(application)
                        }
                    })
                } else if (updateMode === 'remove') {
                    const aids = newItems.map(application => application.aid)
                    const filteredArray = state.stagingObjects.applications.filter(function(application) {
                        return aids.indexOf(application.aid) < 0
                    })
                    state.stagingObjects.applications = filteredArray
                }
            }
        },
        updateLastUploadedFiles: function(state, { newData, updateMode }) {
            if (updateMode === 'add') {
                state.lastUploadedFiles = state.lastUploadedFiles.concat(newData)
            } else if (updateMode === 'clear') {
                state.lastUploadedFiles = newData
            }
        },
        setShowUploadSnackbar: function(state, newVal) {
            state.showUploadSnackbar = newVal
        },
        clearStagingObjects: function(state, itemType) {
            if (itemType === 'files') {
                state.stagingObjects.files = []
            } else if (itemType === 'tables') {
                state.stagingObjects.tables = []
            } else if (itemType === 'applications') {
                state.stagingObjects.applications = []
            } else if (itemType === 'all') {
                state.stagingObjects.files = []
                state.stagingObjects.tables = []
                state.stagingObjects.applications = []
            }
        }
    },
    actions: {
        setPathArray: function(context, newVal) {
            context.commit('setPathArray', newVal)
        },
        resetSnapshotState: function(context) {
            context.commit('resetSnapshotState')
        },
        fetchSnapshotQuotaData: function(context, snid) {
            return $axios.post(`snapshots/${snid}/quota_usage`).then(response => {
                context.commit('setSnapshotQuotaData', response.data)
            })
        },
        fetchApplications: function(context, snid) {
            context.commit('setFetching', { fetchingType: 'applications', val: true })
            $axios
                .get(`snapshots/${snid}/applications`)
                .then(function(response) {
                    context.commit('setApplications', response.data)
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingType: 'applications', val: false })
                })
        },
        fetchQueries: function(context, snid) {
            $axios.get(`snapshots/${snid}/queries`).then(function(response) {
                context.commit('setSnapshotQueries', response.data)
            })
        },
        setLatestQueryBuilderQueryData: function(context, { table, columns, tableId, filters, rowLimit, query }) {
            context.commit('setLatestQueryBuilderQueryData', {
                table: table,
                columns: columns,
                tableId: tableId,
                filters: filters,
                rowLimit: rowLimit,
                query: query
            })
        },
        setCurrentFileUnderRevisionData: function(context, { fileName, fid }) {
            return $axios.get(`/files/${fid}/list_versions`).then(response => {
                context.commit('setCurrentFileUnderRevisionData', { fileName: fileName, fid: fid, prevData: response.data })
            })
        },
        setTablesPath: function(context, snid) {
            $axios.get(`/snapshots/${snid}/tables_path`).then(response => {
                context.commit('setTablesPath', response.data)
            })
        },
        fetchFullQueryData: function(context, qid) {
            return $axios
                .get(`/queries/${qid}`)
                .then(response => {
                    context.commit('setFullQueryData', response.data)
                })
                .catch(() => {
                    context.dispatch('showSnackBar', {
                        snackBarText: 'Failed to fetch query data, try refreshing the page.',
                        snackBarTimeout: 10000,
                        snackBarIcon: 'error'
                    })
                })
        },
        restoreSnapshotToCurrentState: function(context, { snid, createBackup }) {
            context.commit('setSnapshotRestoreState', true)
            context.dispatch(
                'showSnackBar',
                {
                    snackBarText: 'Restoring snapshot, please wait...',
                    snackBarTimeout: 5000,
                    snackBarIcon: 'info'
                },
                {
                    root: true
                }
            )
            $axios
                .put(`/snapshots/${snid}/restore_async`, { auto_backup: createBackup })
                .then(response => {
                    context.dispatch(
                        'showSnackBar',
                        {
                            snackBarText: 'Snapshot restoration has started, you will receive an email once the operation has finished',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'info'
                        },
                        {
                            root: true
                        }
                    )
                })
                .catch(() => {
                    context.dispatch(
                        'showSnackBar',
                        {
                            snackBarText: 'Snapshot restoration failed!',
                            snackBarTimeout: 10000,
                            snackBarIcon: 'error'
                        },
                        {
                            root: true
                        }
                    )
                })
                .finally(() => {
                    context.commit('setSnapshotRestoreState', false)
                })
        },
        setQueryPreviewData: function(context, { data, sql, qid }) {
            context.commit('setQueryPreviewData', { data: data, sql: sql, qid: qid })
        },
        openDirectory: function(context, { snid, nextDirectory }) {
            context.commit('appendPathArray', nextDirectory)
        },
        setTreeLevel: function(context, { snid, level }) {
            context.commit('setTreeLevel', level)
            context.dispatch('fetchCurrentFiles', { snid: snid, setFetchingStatus: true })
        },
        setFolderType: function(context, type) {
            context.commit('setFolderType', type)
        },
        fetchCurrentFilesWithHistory: function(context, url) {
            $axios
                .get(`${url}?history=true`)
                .then(response => {
                    const files = response.data.filter(file => file.type !== 'broken_link')
                    const currentPath = context.state.pathArray.join('/').substr(1)
                    if (
                        files.length > 0 &&
                        files[0].local_path === currentPath &&
                        files[0].area === context.state.fileAreaType &&
                        context.rootState.route.params.snid &&
                        files[0].snid.toString() === context.rootState.route.params.snid.toString()
                    ) {
                        context.commit('setCurrentFiles', files)
                    }
                })
                .catch(error => {
                    console.log(error)
                    context.dispatch('showSnackBar', { snackBarText: 'Fetching files failed, try reloading the page.', snackBarIcon: 'error' }, { root: true })
                })
        },
        fetchCurrentFiles: function(context, { snid, setFetchingStatus }) {
            if (snid !== undefined) {
                context.commit('setFetching', { fetchingType: 'files', val: setFetchingStatus })
                context.dispatch('getUserInfo', null, { root: true }).finally(() => {
                    if (context.rootState.userInfo && context.rootState.userInfo.is_active === true && context.rootState.userInfo.is_sysadmin !== undefined) {
                        if (context.state.filePollingInterval !== null) {
                            context.commit('clearFilePollingInterval')
                        }
                        const localPath = context.state.pathArray.join('/')
                        var apiURL
                        const baseURL = `/snapshots/${snid}/fs/${context.state.fileAreaType}`
                        apiURL = `${baseURL}${localPath}`
                        if (context.state.fileAreaType === 'home' && context.rootState.userInfo.is_sysadmin === false) {
                            apiURL = `${baseURL}/${context.rootState.userInfo.uid}${localPath}`
                        }
                        $axios
                            .get(apiURL)
                            .then(response => {
                                const files = response.data.filter(file => file.type !== 'broken_link')
                                context.commit('setCurrentFiles', files)
                                context.commit('setFetching', { fetchingType: 'files', val: false })
                                context.dispatch('fetchCurrentFilesWithHistory', apiURL)
                                // context.commit(
                                //    'setFilesPollingInterval',
                                //    setInterval(() => {
                                //        if (context.rootState.route.name === 'snapshot-files') {
                                //            context.dispatch('fetchCurrentFilesWithHistory', apiURL)
                                //        }
                                //    }, 60000)
                                // )
                            })
                            .catch(error => {
                                console.log(error)
                                context.commit('setFetching', { fetchingType: 'files', val: false })
                                if (context.state.currentTreeLevel !== 0) {
                                    context.dispatch('setTreeLevel', { snid, level: 0 })
                                }
                                if (context.getters.currentSnapshotData && context.getters.currentSnapshotData.filesystem_prefix) {
                                    context.dispatch('showSnackBar', { snackBarText: 'Files fetching failed', snackBarIcon: 'error' }, { root: true })
                                }
                            })
                    }
                })
            }
        },
        clearCurrentFiles: function(context) {
            context.commit('clearCurrentFiles')
        },
        clearFilePollingInterval: function(context) {
            context.commit('clearFilePollingInterval')
        },
        updateLastUploadedFiles: function(context, { newData, updateMode }) {
            context.commit('updateLastUploadedFiles', { newData, updateMode })
        },
        setShowUploadSnackbar: function(context, newVal) {
            context.commit('setShowUploadSnackbar', newVal)
        },
        emptyTableData: function(context, clearQueryData) {
            context.commit('emptyTableData', clearQueryData)
        },
        fetchCurrentTables: function(context, snid) {
            context.commit('setFetching', { fetchingType: 'tables', val: true })
            $axios
                .get(`/snapshots/${snid}/ts/tables`)
                .then(response => {
                    context.commit('setCurrentTables', response.data)
                    context.commit('setFetching', { fetchingType: 'tables', val: false })
                })
                .catch(() => {
                    context.dispatch(
                        'showSnackBar',
                        { snackBarText: 'Failed to fetch table data, try refreshing the page.', snackBarIcon: 'error' },
                        { root: true }
                    )
                    context.commit('setFetching', { fetchingType: 'tables', val: false })
                })
        },
        fetchColumnAndPreviewData: function(context, tid) {
            context.dispatch('fetchTablePreviewData', tid)
            context.dispatch('fetchCurrentColumns', tid)
        },
        fetchTablePreviewData: function(context, tid) {
            context.commit('setFetching', { fetchingType: 'preview', val: true })
            return $axios
                .get(`tables/${tid}/preview`)
                .then(response => {
                    if (response.data.length > 0) {
                        const headers = Object.keys(response.data[0])
                        context.commit('setPreviewData', { keys: headers, data: response.data })
                    } else {
                        context.commit('setPreviewData', { keys: [], data: [] })
                    }
                })
                .catch(() => {
                    context.dispatch(
                        'showSnackBar',
                        { snackBarText: 'Failed to fetch table preview data, try refreshing the page.', snackBarIcon: 'error' },
                        { root: true }
                    )
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingType: 'preview', val: false })
                })
        },
        fetchCurrentColumns: function(context, tid) {
            context.commit('setFetching', { fetchingType: 'columns', val: true })
            return $axios
                .get(`/tables/${tid}/columns`)
                .then(response => {
                    context.commit('setCurrentColumns', response.data)
                })
                .catch(() => {
                    context.dispatch(
                        'showSnackBar',
                        { snackBarText: 'Failed to fetch table column data, try refreshing the page.', snackBarIcon: 'error' },
                        { root: true }
                    )
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingType: 'columns', val: false })
                })
        },
        fetchCurrentImages: function(context, sid) {
            context.commit('setFetching', { fetchingType: 'applicationImages', val: true })
            $axios
                .get(`/spaces/${sid}/images`)
                .then(response => {
                    context.commit('updateImageNamesList', response.data)
                })
                .catch(error => {
                    context.dispatch(
                        'showSnackBar',
                        { snackBarText: 'Failed to fetch application data, try refreshing the page.', snackBarIcon: 'error' },
                        { root: true }
                    )
                    console.log(error)
                })
                .finally(() => {
                    context.commit('setFetching', { fetchingType: 'applicationImages', val: false })
                })
        },
        setFilesToDistribute: function(context, newFiles) {
            context.commit('setFilesToDistribute', newFiles)
        },
        updateFileDescription: function(context, { newVal, fid }) {
            context.commit('updateFileDescription', { newVal, fid })
        },
        updateStagingObjects: function(context, { itemType, newItems, updateMode }) {
            context.commit('updateStagingObjects', { itemType, newItems, updateMode })
        },
        clearStagingObjects: function(context, itemType) {
            context.commit('clearStagingObjects', itemType)
        }
    },
    getters: {
        snapshotLongNameById: (state, getters, rootState) => snid => {
            if (snid !== undefined && snid !== null && rootState.instanceStore.instanceSnapshots) {
                const snapshots = rootState.instanceStore.instanceSnapshots.filter(snapshot => snapshot.snid.toString() === snid.toString())
                if (snapshots.length) {
                    return snapshots[0].long_id
                } else {
                    return null
                }
            } else {
                return null
            }
        },
        snapshotDescriptionById: (state, getters, rootState) => snid => {
            if (snid !== undefined && snid !== null && rootState.instanceStore.instanceSnapshots) {
                const snapshots = rootState.instanceStore.instanceSnapshots.filter(snapshot => snapshot.snid.toString() === snid.toString())
                if (snapshots.length) {
                    return snapshots[0].description
                } else {
                    return null
                }
            } else {
                return null
            }
        },
        snapshotTimestampById: (state, getters, rootState) => snid => {
            if (snid !== undefined && snid !== null && rootState.instanceStore.instanceSnapshots) {
                const snapshots = rootState.instanceStore.instanceSnapshots.filter(snapshot => snapshot.snid.toString() === snid.toString())
                if (snapshots.length) {
                    return snapshots[0].snapshot_timestamp
                } else {
                    return null
                }
            } else {
                return null
            }
        },
        isDevelopment: (state, getters, rootState) => {
            if (getters.developmentSnapshot && rootState.route.params.snid !== undefined) {
                return getters.developmentSnapshot.snid.toString() === rootState.route.params.snid.toString()
            } else {
                return null
            }
        },
        developmentSnapshot: (state, getters, rootState) => {
            if (rootState.instanceStore.instanceSnapshots) {
                return rootState.instanceStore.instanceSnapshots.filter(snapshot => isCurrentState(snapshot.short_id) === true)[0]
            } else {
                return []
            }
        },
        currentSnapshotData: (state, getters, rootState) => {
            if (rootState.instanceStore.instanceSnapshots) {
                return rootState.instanceStore.instanceSnapshots.find(snapshot => snapshot.snid.toString() === rootState.route.params.snid.toString())
            } else {
                return []
            }
        },
        nonDevelopmentSnapshots: (state, getters, rootState) => {
            if (rootState.instanceStore.instanceSnapshots) {
                return rootState.instanceStore.instanceSnapshots.filter(snapshot => isCurrentState(snapshot.short_id) === false)
            } else {
                return []
            }
        },
        currentFileLocalPath: state => {
            if (state.pathArray && state.pathArray.length > 1) {
                return state.pathArray.join('/')
            }
        },
        appLongNameById: state => aid => {
            const apps = state.applications.filter(app => app.aid === parseInt(aid, 10))
            if (apps.length) {
                return apps[0].long_id
            } else {
                return null
            }
        },
        tableShortNameById: state => tid => {
            const tables = state.currentTables.filter(table => table.tid === parseInt(tid, 10))
            if (tables.length) {
                return tables[0].short_id
            } else {
                return null
            }
        },
        isStagingEmpty: state => {
            if (!state.stagingObjects.files.length && !state.stagingObjects.tables.length && !state.stagingObjects.applications.length) {
                return true
            } else {
                return false
            }
        },
        currentSnapshotLockMode: (state, getters, rootState) => {
            if (getters.developmentSnapshot && rootState.instanceStore.instanceSnapshots) {
                const currentStateData = rootState.instanceStore.instanceSnapshots.find(
                    snapshot => snapshot.snid.toString() === getters.developmentSnapshot.snid.toString()
                )
                if (currentStateData) {
                    return currentStateData.lock_mode_name
                } else {
                    return null
                }
            }
        }
    }
}

export default snapshotStore
