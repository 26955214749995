<template>
    <v-app-bar
        v-scroll="onScroll"
        :color="isScrolling || $route.name !== 'privacy-policy' ? 'transparent' : 'rgb(23, 167, 193)'"
        :flat="!isScrolling"
        app
        dark
        hide-on-scroll
    >
        <router-link :to="{ name: 'welcome' }">
            <v-img class="ml-3" contain max-width="110px" max-height="22px" src="@/assets/Nuvolos-transparent-white.svg" />
        </router-link>
        <v-spacer />
        <v-toolbar-items class="pa-0">
            <v-btn text href="#requestTrial" @click.prevent="$vuetify.goTo('#requestTrial')">request a trial</v-btn>
        </v-toolbar-items>
    </v-app-bar>
</template>

<script>
// Utilities

export default {
    data: () => ({
        isScrolling: false
    }),
    methods: {
        onScroll() {
            this.isScrolling = (window.pageYOffset || document.documentElement.scrollTop || 0) > 25
        }
    }
}
</script>
