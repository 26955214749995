<template>
    <section id="home" class="pa-0">
        <hero :login-action="loginAction" />

        <welcome />

        <demo />

        <features />

        <Testimonials />
        <RequestTrial />
    </section>
</template>

<script>
import Hero from '@/components/sections/Hero'

const Demo = () => import('@/components/sections/Demo')
const Features = () => import('@/components/sections/Features')
const Welcome = () => import('@/components/sections/Welcome')
const Testimonials = () => import('@/components/sections/Testimonials')
const RequestTrial = () => import('@/components/sections/RequestTrial')

export default {
    metaInfo() {
        return {
            title: ''
        }
    },

    components: {
        Demo,
        Features,
        Hero,
        Welcome,
        Testimonials,
        RequestTrial
    },
    computed: {
        cardFeatures() {
            return []
        },
        listFeatures() {
            return []
        },
        testimonials() {
            return []
        }
    },
    props: { 'login-action': Function }
}
</script>
